import {
  Facebook,
  Flight,
  Instagram,
  Notifications,
  YouTube,
} from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  Typography,
  styled,
  Link,
  InputBase,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import { Box, width } from "@mui/system";
import React, { useState } from "react";
import logo from "../img/logo_fazerasmalas_white.png";
import MailIcon from "@mui/icons-material/Mail";
import profilePhoto from "../img/profile_Rodrigo.png";

const StyledToolBar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#195779",
  height: "100px",
});

// const Search = styled("div")(({ theme }) => ({
//   backgroundColor: "White",
//   padding: "0 10px",
//   borderRadius: theme.shape.borderRadius,
//   width: "30%",
// }));

const Icons = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("sm")]: { gap: "10px" },
}));

const IconsBackup = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: { display: "flex" },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("sm")]: { display: "none" },
}));

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <AppBar position="sticky">
      <StyledToolBar>
        {/* <Typography variant="h6" sx={{ display: { xs: "none", sm: "block" } }}>
          Fazer As Malas
        </Typography> */}
        {/* <Flight sx={{ display: { xs: "block", sm: "none" } }} /> */}

        {/* <Box
          component="img"
          sx={{
            position: "fixed",
            left: "60px",
            height: 60,
            display: { xs: "none", sm: "block" },
          }}
          alt="Logo"
          src={logo}
        /> */}

        {/* Logotipo Fazer as Malas */}
        <Link href="/">
          <Box
            component="img"
            sx={{
              position: "flex",
              height: 70,
              display: { xs: "block", sm: "block" },
            }}
            alt="Logo"
            src={logo}
          />
        </Link>

        <Icons>
          <a href="https://www.instagram.com/fazerasmalas/" target={"_blank"}>
            <Instagram fontSize="large" sx={{ color: "#FFF" }} />
          </a>
          <a href="https://www.youtube.com/fazerasmalas/" target={"_blank"}>
            <YouTube fontSize="large" sx={{ color: "#FFF" }} />
          </a>
          <a href="https://www.facebook.com/fazerasmalas/" target={"_blank"}>
            <Facebook fontSize="large" sx={{ color: "#FFF" }} />
          </a>
        </Icons>

        {/* Search area  */}
        {/* <Search>
          <InputBase placeholder="seach" />
        </Search> */}

        {/* <Icons>
          
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
          <Badge badgeContent={5} color="error">
            <Notifications />
          </Badge>
          
          <Avatar
            sx={{ width: 60, height: 60 }}
            alt="Rodrigo Valadao"
            src={profilePhoto}
            onClick={(e) => setOpen(true)}
          /> 
        </Icons>*/}

        {/* <UserBox onClick={(e) => setOpen(true)}>
          <Avatar
            sx={{ width: 60, height: 60 }}
            alt="Rodrigo Valadao"
            src={profilePhoto}
          />
          <Typography variant="span">Rodrigo Valadao</Typography>
        </UserBox> */}
      </StyledToolBar>
      {/* <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>My account</MenuItem>
        <MenuItem>Logout</MenuItem>
      </Menu> */}
    </AppBar>
  );
};

export default Navbar;
