import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { theme } from "./theme";
import Home from "./pages/home";
import Downloads from "./pages/downloads";
import Contact from "./pages/contact";
import Sobre from "./pages/sobre";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {/* <Router>
        <App>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/downloads" component={Downloads} />
            <Route path="/sobre" component={Sobre} />
            <Route path="/contato" component={Contact} />
          </Switch>
        </App>
      </Router> */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
