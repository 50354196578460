import { Box } from "@mui/material";

import Post from "./Post";
import React from "react";
import Gallery from "./Gallery";

const Feed = () => {
  return (
    <Box flex={4} p={2}>
      {/* <Post /> */}
      <Gallery />
    </Box>
  );
};

export default Feed;
